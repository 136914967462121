/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import Input, { type InputOnChange, type InputValue } from '../../ui/Input'
import Button from '../../ui/Button'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { useIonRouter } from '@ionic/react'
import getErrorMessage from '#utils/getErrorMessage'
import { useTranslation } from 'react-i18next'
import InputPasswordTest from '#components/ui/InputPasswordTest'
import useAuthState from '#hooks/useAuthState'
import { useHistory } from 'react-router'

export default function LoginForm(): JSX.Element {
  const [email, setEmail] = useState<InputValue>('')

  const [password, setPassword] = useState<InputValue>('')

  const { loginWithEmail, loginWithToken } = useAuthState()

  const { startLoading, stopLoading } = useLoading('signingIn')

  const { showToast } = useToast('warning')

  const { push } = useIonRouter()

  const hisotry = useHistory()

  const { i18n } = useTranslation()

  const handleChanges: InputOnChange = (e) => {
    const { name, value } = e.target

    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
  }

  // const testHandleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
  //   e.preventDefault()
  //   loginWithToken(
  //     'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcyMjA5NDU1NSwiZXhwIjoxNzIyMDk4MTU1LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay0yZ3N2bEB0YWNrcGF5LTY4OTA2LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZmlyZWJhc2UtYWRtaW5zZGstMmdzdmxAdGFja3BheS02ODkwNi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6IjhiM2JiY2RkLThlZTktNDk3My1hOGI3LWQ0NGJjMjY5YmFmNCJ9.o4JOXsMvngMQ21Q__rtnPfVfw9p2RmqQTuySriZFtnfcNrH0qsDd1zNT1OCxxslcrzCpJCJZzxfk8-dcZrx_E_uNXIo2DXfIoxgRVdtexkGPVErJQr0mX4AkSzRhWxfnSNFT_QJxCoaVn07L1DDPBWkPelnf2xIZvEt8WDKhjTqljxGPXCo6cvWWtgbiB5HOA2YovT8_FcoEHfevs6PfT-6jZ2x3eQHASeXKFQZ-UftgjvshuOVoRdR012HWn2-ChbBXdaiKAkrP08D8NHcK1RJm8JrUJR0Lvi3tr6Z5qSpB9jJ_qMarWGDskHUvxbeQACJrOYlv3RjeLd-ql5NFdw'
  //   )
  //     .then((res) => {
  //       i18n
  //         .changeLanguage(res?.lang ?? 'en')
  //         .then(() => {
  //           stopLoading()
  //           console.log('User fetched')
  //           hisotry.push(`/dashboard/${res.category}/home`)
  //         })
  //         .catch((error) => {
  //           console.log(error)
  //           stopLoading()
  //           showToast(undefined, getErrorMessage(error))
  //         })
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       stopLoading()
  //       showToast(undefined, getErrorMessage(error))
  //     })
  // }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (email === '' && password === '') {
      showToast(undefined, 'login.emptyFields')
    } else if (email === '' || typeof email !== 'string') {
      showToast(undefined, 'login.emptyEmail')
    } else if (password === '' || typeof password !== 'string') {
      showToast(undefined, 'login.emptyPassword')
    } else {
      startLoading()
      loginWithEmail(email, password)
        .then((res) => {
          i18n
            .changeLanguage(res?.lang ?? 'en')
            .then(() => {
              stopLoading()
              console.log('User fetched')
              push(`/dashboard/${res.category}/home`, 'root')
            })
            .catch((error) => {
              console.log(error)
              stopLoading()
              showToast(undefined, getErrorMessage(error))
            })
        })

        .catch((error) => {
          stopLoading()
          showToast(undefined, getErrorMessage(error))
        })
    }
  }

  return (
    <form className='space-y-8' onSubmit={handleSubmit}>
      <div>
        <Input
          value={email}
          onIonInput={handleChanges}
          name='email'
          type='email'
          labelOptions={{
            children: 'email'
          }}
          data-testid='input-email'
        />
      </div>
      <div>
        <InputPasswordTest
          data-testid='input-password'
          name='password'
          password={password}
          onPasswordChange={handleChanges}
          missingPassword
          showValidityPass={false}
        />
      </div>

      <div className='w-full pt-5'>
        <Button className='w-full ion-no-margin' type='submit'>
          continue
        </Button>
      </div>
    </form>
  )
}
