import AddressAutoComplete from '#components/common/AddressAutoComplete'
import CountrySelect from '#components/common/CountrySelect'
import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import Button from '#components/ui/Button'
import { type DateTimeOnChage } from '#components/ui/DateTime'
import Input, { type InputOnChange } from '#components/ui/Input'
import Modal from '#components/ui/Modal'
import Text from '#components/ui/Text'
import { getIsoByCountry } from '#constants/country'
import { useUserContext } from '#contexts/UserContext'
import useLoading from '#hooks/useLoading'
import useToast from '#hooks/useToast'
import { type Gender, type Address, type Person } from '#tackpay-sdk'
import formattedBirthdate from '#utils/formattedBirthdate'
import getErrorMessage from '#utils/getErrorMessage'
import isDesktop from '#utils/isDesktop'
import sdk from '#utils/sdk'
import {
  IonButton,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonRow
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function IdentityForm(): JSX.Element {
  const { user, refetch } = useUserContext()

  const { t } = useTranslation()

  const { startLoading, stopLoading } = useLoading('stipeAccountSaving')
  const { showToast } = useToast('warning')
  const { showToast: successToast } = useToast('success')

  const [openModal, setOpenModal] = useState<boolean>(false)

  const [person, setPerson] = useState<Partial<Person>>({
    ...user?.person
  })

  const [address, setAddress] = useState<Partial<Address>>({
    ...user?.person?.address
  })

  const [ssn, setSsn] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (user?.person != null) {
      setPerson({ ...user?.person })
    }

    if (user?.person?.address != null) {
      setAddress({ ...user?.person?.address })
    }
  }, [user?.person, user?.person?.address])

  const getBirthdateString = (): string | undefined => {
    if (person?.birthdate != null) {
      return `${person?.birthdate.day}/${person?.birthdate.month}/${person?.birthdate.year}`
    }
    return undefined
  }

  const handleGender: SelectOnChangeEvent = (event) => {
    const gender = event.target.value as Gender
    setPerson({ ...person, gender })
  }

  const handleBirthdateChange: DateTimeOnChage = (e) => {
    const { value } = e.detail
    if (typeof value === 'string') {
      const birthdateDate = formattedBirthdate(value)
      setPerson({ ...person, birthdate: birthdateDate })
    }
  }

  const handleInputChanges: InputOnChange = (event) => {
    const { name, value } = event.target
    if (typeof value === 'string') {
      if (name === 'firstName') setPerson({ ...person, first_name: value })
      if (name === 'lastName') setPerson({ ...person, last_name: value })
      if (name === 'city') setAddress({ ...address, city: value })
      if (name === 'street') setAddress({ ...address, street: value })
      if (name === 'street_number')
        setAddress({ ...address, street_number: value })
      if (name === 'postal_code') setAddress({ ...address, postal_code: value })
      if (name === 'state') setAddress({ ...address, state: value })
      if (name === 'ssn') setSsn(value)
    }
  }

  const handleAddressChange = (addressProps: Partial<Address>): void => {
    setAddress({
      city: addressProps?.city ?? address?.city,
      street: addressProps?.street ?? address?.street,
      street_number: addressProps?.street_number ?? address?.street_number,
      postal_code: addressProps?.postal_code ?? address?.postal_code,
      state: addressProps?.state ?? address?.state,
      type: addressProps?.type ?? address?.type,
      id: addressProps?.id ?? address?.id,
      country_name: address?.country_name,
      country_code: address?.country_code
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (person?.first_name == null) {
      showToast(undefined, 'First name is required')
      return
    }

    if (person?.last_name == null) {
      showToast(undefined, 'Last name is required')
      return
    }

    if (person?.gender == null) {
      showToast(undefined, 'Gender is required')
    }

    if (person?.birthdate == null) {
      showToast(undefined, 'Birthdate is required')
    }

    if (address?.city == null) {
      showToast(undefined, 'City is required')
    }

    if (address?.street == null) {
      showToast(undefined, 'Street is required')
    }

    if (address?.street_number == null) {
      showToast(undefined, 'Street number is required')
    }

    if (address?.postal_code == null) {
      showToast(undefined, 'Postal code is required')
    }

    startLoading()
    handleCreateStripeAccount()
      .then(() => {
        if (refetch != null) {
          void refetch()
        }
        stopLoading()
        successToast(undefined, 'Create account for receiving tips')
      })
      .catch((error) => {
        stopLoading()
        showToast(undefined, getErrorMessage(error))
      })
  }

  const handleCountryChange = (country: string): void => {
    setAddress({
      ...address,
      country_name: country,
      country_code: getIsoByCountry(country)
    })
  }

  const handleCreateStripeAccount = async (): Promise<void> => {
    let person
    if (user?.person == null) {
      person = await sdk.persons.create({
        ...(person as any),
        user: { id: user?.id ?? '', type: 'users' }
      })
    } else if (user?.person != null) {
      person = await sdk.persons.update({
        ...(person as any),
        id: user.person.id
      })
    }

    if (refetch != null) {
      void refetch()
    }

    if (user?.person?.address == null) {
      await sdk.addresses.create({
        ...(address as any),
        person: { id: person?.id, type: 'persons' }
      })
    } else if (user?.person?.address != null) {
      await sdk.addresses.update({
        ...(address as any),
        id: user.person.address.id
      })
    }

    if (refetch != null) {
      void refetch()
    }

    if (user?.stripe_gateway_account == null && user != null) {
      await sdk.stripe_gateway_accounts.create({
        profile_type: 'individual',
        reference_category: 'user',
        ssn,
        user: { id: user?.id, type: 'users' }
      })
    }
  }

  return (
    <div className='pb-10'>
      <Text>stripeEmpty</Text>
      <form className='mt-5 space-y-5' onSubmit={handleSubmit}>
        <div>
          <Input
            name='firstName'
            type='text'
            value={person?.first_name}
            onIonInput={handleInputChanges}
            labelOptions={{ children: 'firstName', capitalize: false }}
            testId='firstName'
          />
        </div>
        <div>
          <Input
            name='lastName'
            type='text'
            value={person?.last_name}
            onIonInput={handleInputChanges}
            labelOptions={{ children: 'lastName', capitalize: false }}
            testId='lastName'
          />
        </div>
        <IonRow className='ion-align-items-center ion-justify-content-between space-x-1 ion-no-padding ion-no-margin'>
          <IonCol className='ion-no-padding ion-no-margin'>
            <IonDatetimeButton
              datetime='date'
              // placeholder='date'
              className='hidden'
              id='open-modal'
            />
            <Input
              type='text'
              value={getBirthdateString()}
              placeholder='DD/MM/YYYY'
              labelOptions={{
                children: 'birthdate',
                capitalize: false
              }}
              onFocus={() => {
                setOpenModal(!openModal)
              }}
            />
            <Modal
              trigger='open-modal'
              keepContentsMounted
              isOpen={openModal}
              onIonModalWillDismiss={() => {
                setOpenModal(false)
              }}
            >
              <IonDatetime
                id='date'
                presentation='date'
                // placeholder='Select Date'
                preferWheel={!isDesktop()}
                doneText='Done'
                value={getBirthdateString()}
                onIonChange={handleBirthdateChange}
              />
              <IonRow className='w-full ion-justify-content-between ion-no-margin ion-no-padding row-button-modal'>
                <IonButton
                  fill='clear'
                  className='w-1/3 ion-no-margin ion-no-padding button-delete font-semibold'
                  onClick={() => {
                    setOpenModal(false)
                  }}
                >
                  Cancel
                </IonButton>

                <IonButton
                  fill='clear'
                  className='w-1/3 ion-no-margin ion-no-padding button-done font-semibold text-green-400'
                  onClick={() => {
                    setOpenModal(false)
                  }}
                >
                  Done
                </IonButton>
              </IonRow>
            </Modal>
          </IonCol>
          <IonCol className='ion-no-margin ion-no-padding'>
            <BasicSelect
              options={{
                childrens: ['male', 'female']
              }}
              placeholder={t('gender') ?? ''}
              interface={isDesktop() ? 'popover' : 'action-sheet'}
              labelOptions={{
                show: true,
                options: {
                  children: 'gender'
                }
              }}
              onIonChange={handleGender}
              value={person?.gender}
            />
          </IonCol>
        </IonRow>
        {user?.person?.address?.country_code === 'US' && (
          <div>
            <Input
              name='ssn'
              type='text'
              value={ssn}
              onIonInput={handleInputChanges}
              labelOptions={{ children: 'ssn', capitalize: false }}
              testId='ssn'
            />
          </div>
        )}
        <div className='mt-5'>
          <AddressAutoComplete setAddress={handleAddressChange} />
          {user?.person?.address == null && (
            <CountrySelect
              country={address?.country_name ?? ''}
              setSelectionCountry={handleCountryChange}
            />
          )}

          <div className='mt-5'>
            <Input
              name='city'
              type='text'
              value={address?.city}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'city'
              }}
              testId='city'
            />
          </div>

          <div className='mt-3'>
            <Input
              name='street'
              type='text'
              value={address?.street}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'street'
              }}
              testId='street'
            />
          </div>
          <div className='mt-3'>
            <Input
              name='street_number'
              type='text'
              value={address?.street_number}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'streetNumber',
                capitalize: false
              }}
              testId='streetNumber'
            />
          </div>
          <div className='mt-3'>
            <Input
              name='postal_code'
              type='text'
              value={address?.postal_code}
              onIonInput={handleInputChanges}
              labelOptions={{
                children: 'postalCode',
                capitalize: false
              }}
              testId='postalCode'
            />
          </div>
          <div className='mt-3'>
            <Input
              name='state'
              type='text'
              value={address?.state}
              onIonInput={handleInputChanges}
              labelOptions={{ children: 'state' }}
              testId='state'
            />
          </div>
        </div>
        <Button className='w-full' type='submit'>
          save
        </Button>
      </form>
    </div>
  )
}
