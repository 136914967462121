/* eslint-disable prettier/prettier */
import ButtonStep from '#components/common/ButtonStep'
import BasicSelect, {
  type SelectOnChangeEvent
} from '#components/ui/BasicSelector'
import { type DateTimeOnChage } from '#components/ui/DateTime'
import Input, { type InputOnChange } from '#components/ui/Input'
import Modal from '#components/ui/Modal'
import { useUserContext } from '#contexts/UserContext'
import useCreateTeamStore from '#hooks/useCreateTeamStore'
import useToast from '#hooks/useToast'
import { type Gender, type UserCategory } from '#tackpay-sdk'
import { adapterModal } from '#utils/adapter'
import birthdateValidate from '#utils/birthdateValidator'
import { isGender } from '#utils/isGenger'
import textUtil from '#utils/textUtil'
import {
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonRow,
  useIonRouter
} from '@ionic/react'
import { parseISO, format } from 'date-fns'
import { useEffect, useState } from 'react'

interface Props {
  category: UserCategory
}
export default function AddTeamPersonalDataForm({
  category
}: Props): JSX.Element {
  const { user } = useUserContext()

  const {
    setPersonCreate,
    personCreate,
    stripeGatewayAccount,
    setStripeGatewayAccount
  } = useCreateTeamStore()

  const { push } = useIonRouter()

  const { showToast } = useToast('warning')

  const defaultDate = new Date()

  defaultDate.setFullYear(defaultDate.getFullYear() - 18)

  const personBirthdate =
    personCreate?.birthdate != null
      ? new Date(
        personCreate?.birthdate?.year,
        personCreate?.birthdate?.month,
        personCreate?.birthdate?.day
      ).toISOString()
      : defaultDate.toISOString()

  const [birthdate, setBirthdate] = useState<string | undefined>(
    user?.person?.birthdate != null
      ? format(parseISO(personBirthdate), 'dd/MM/yyyy')
      : undefined
  )

  const [isoBirthdate, setIsoBirthdate] = useState<string>(personBirthdate)

  const [hasSsn, setHasSsn] = useState<boolean>(false)

  const handleInputChanged: InputOnChange = (e) => {
    const { name, value } = e.target
    if (typeof value === 'string') {
      if (name === 'firstName') {
        setPersonCreate({ first_name: value })
      }

      if (name === 'lastName') {
        setPersonCreate({ last_name: value })
      }

      if (name === 'ssn') {
        setStripeGatewayAccount({
          ssn: value
        })
      }
    }
  }

  const handleBirthdateChange: DateTimeOnChage = (e) => {
    const date = e.detail.value as string
    const formattedDate = format(parseISO(date), 'dd/MM/yyyy')
    setBirthdate(formattedDate)
    const [day, month, year] = formattedDate.split('/')
    const dayNumber = Number(day)
    const monthNumber = Number(month)
    const yearNumber = Number(year)
    const isoDate = new Date(yearNumber, monthNumber, dayNumber).toISOString()
    setIsoBirthdate(isoDate)
    setPersonCreate({
      birthdate: {
        day: dayNumber,
        month: monthNumber,
        year: yearNumber
      }
    })
  }

  const handleGender: SelectOnChangeEvent = (event) => {
    const gender = event.target.value as Gender
    setPersonCreate({ gender })
  }

  useEffect(() => {
    setBirthdate(
      personCreate?.birthdate != null
        ? format(parseISO(personBirthdate), 'dd/MM/yyyy')
        : undefined
    )
  }, [personCreate])

  const validateStep = (): boolean => {
    const invalid = false
    if (textUtil.isEmpty(personCreate?.first_name)) {
      showToast(undefined, 'registration.firstNameRequired')
      return invalid
    }

    if (textUtil.isEmpty(personCreate?.last_name)) {
      showToast(undefined, 'registration.lastNameRequired')
      return invalid
    }

    if (textUtil.isEmpty(personCreate?.gender)) {
      showToast(undefined, 'registration.genderRequired')
      return invalid
    }

    if (typeof personCreate?.birthdate === 'undefined') {
      showToast(undefined, 'registration.birthdateRequired')
      return invalid
    }

    if (!isGender(personCreate?.gender)) {
      showToast(undefined, 'registration.genderInvalid')
      return invalid
    }

    if (typeof personCreate?.birthdate !== 'undefined') {
      const validateDay = birthdateValidate.isValidDay(
        personCreate?.birthdate.day
      )
      if (!validateDay) {
        showToast(undefined, 'registration.birthdateInvalidDay')
        return invalid
      }

      const validateMonth = birthdateValidate.isValidMonth(
        personCreate?.birthdate.month
      )

      if (!validateMonth) {
        showToast(undefined, 'registration.birthdateInvalidMonth')
        return invalid
      }

      const validateYear = birthdateValidate.isValidYear(
        personCreate?.birthdate.year
      )

      if (!validateYear) {
        showToast(undefined, 'registration.birthdateInvalidYear')
        return invalid
      }

      const { day, month, year } = personCreate?.birthdate

      const validateAge = birthdateValidate.isOfLegalAge(day, month, year)

      if (!validateAge) {
        showToast(undefined, 'registration.birthdateInvalidAge')
        return invalid
      }
    }

    return !invalid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const isValid = validateStep()

    if (isValid) {
      push(`/dashboard/${category}/jars/add/addresses`)
    }
  }

  const handleBack = (): void => {
    push(`/dashboard/${category}/jars/add/companies`, 'back')
  }

  useEffect(() => {
    if (user?.person?.address != null) {
      setHasSsn(user?.person?.address.country_code === 'US')
    }
  }, [user])

  return (
    <form onSubmit={handleSubmit} className='space-y-5'>
      <div className='mt-5'>
        <Input
          name='firstName'
          type='text'
          value={personCreate?.first_name}
          onIonInput={handleInputChanged}
          labelOptions={{
            children: 'firstName'
          }}
          testId='firstName'
        />
      </div>
      <div>
        <Input
          name='lastName'
          type='text'
          value={personCreate?.last_name}
          onIonInput={handleInputChanged}
          labelOptions={{ children: 'lastName' }}
          testId='lastName'
        />
      </div>

      {hasSsn && (
        <div>
          <Input
            type='text'
            name='ssn'
            value={stripeGatewayAccount?.ssn}
            onIonInput={handleInputChanged}
            labelOptions={{
              children: 'ssn',
              capitalize: false
            }}
            helperText='ssnHelperText'
            testId='ssn'
          />
        </div>
      )}

      <IonRow className='ion-align-items-center ion-justify-content-between ion-no-padding ion-no-margin'>
        <IonCol
          size='5.4'
          id='open-modal'
          className='ion-no-padding ion-no-margin'
        >
          <IonDatetimeButton
            datetime='date'
            // placeholder='date'
            className='hidden'
          />
          <Input
            type='text'
            value={birthdate}
            placeholder='DD/MM/YYYY'
            labelOptions={{
              children: 'birthdate',
              capitalize: false
            }}
          />
        </IonCol>
        <IonCol size='5.4' className='ion-no-padding ion-no-margin'>
          <BasicSelect
            options={{
              childrens: ['male', 'female']
            }}
            interface={adapterModal}
            placeholder='Gender'
            labelOptions={{
              show: true,
              options: {
                children: 'gender'
              }
            }}
            onIonChange={handleGender}
            value={personCreate?.gender}
          />
        </IonCol>
      </IonRow>
      <Modal keepContentsMounted trigger='open-modal'>
        <IonDatetime
          id='date'
          presentation='date'
          // placeholder='Select Date'
          preferWheel
          showDefaultButtons
          value={isoBirthdate}
          onIonChange={handleBirthdateChange}
        />
      </Modal>
      <div className='pt-5'>
        <ButtonStep nextType='submit' handleBack={handleBack} />
      </div>
    </form>
  )
}
