import AuthLayout from '#components/layouts/auth/AuthLayout'
import CheckoutSection from '#components/pages/checkouts/CheckoutSection'
import TabCheckout from '#components/pages/checkouts/TabCheckout'
import { useParams } from 'react-router'
import { type CheckoutRoutesParams } from '../CheckoutRoutes'
import { useEffect } from 'react'
import { useIonRouter } from '@ionic/react'

export default function Checkout(): JSX.Element {
  const { tackpayId } = useParams<CheckoutRoutesParams>()

  const { push } = useIonRouter()

  useEffect(() => {
    if (tackpayId === 'AJ56' || tackpayId === 'LIWI') {
      push('/demo')
    }
    // else if (tackpayId === 'G7Q2') {
    //   push('/demo/usa/company/select')
    // }
  }, [tackpayId])

  return (
    <AuthLayout
      headerOptions={{
        showLogo: true,
        showLanguageSwitcher: true
      }}
    >
      <TabCheckout section='select' />
      <CheckoutSection tackpayIdValue={tackpayId} />
    </AuthLayout>
  )
}
